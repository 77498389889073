#comidasSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 100vh;
  margin: 0 100px;
}

.titulo-comidas {
  font-family: "Open Sans", sans-serif;
  margin-top: 20px !important;
  font-size: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* Mostrar 3 columnas de tamaño flexible */
  grid-template-rows: repeat(
    2,
    auto
  ); /* Mostrar 2 filas de tamaño automático */
  gap: 50px; /* Espacio entre los elementos del grid */
}

.grid-item {
  /* Estilos para cada elemento del grid */
  display: flex; /* Mostrar los elementos en línea */
  flex-direction: column; /* Cambiar la dirección de los elementos a columna */
  align-items: center; /* Centrar verticalmente los elementos */
  padding: 10px; /* Espacio interno alrededor del elemento */
  text-align: center; /* Centrar el texto horizontalmente */
}

.grid-item img {
  width: 200px; /* Ancho fijo para la imagen */
  height: 200px; /* Altura fija para la imagen */
  object-fit: cover; /* Ajustar la imagen para que ocupe todo el espacio sin deformarla */
  border-radius: 50%; /* Hacer la imagen circular */
  margin-bottom: 10px; /* Agregar un margen inferior */
}

.grid-item h2 {
  /* Estilos para el título */
  font-size: 1.5em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif; /* Tipo de letra redondeado */
}

.grid-item p {
  /* Estilos para la descripción */
  display: block;
  font-size: 1em; /* Tamaño de letra más pequeño */
  margin-block-start: 0.2em;
  margin-block-end: 0em;
  margin-inline-start: 20px;
  margin-inline-end: 0px;
  color: #525050; /* Color gris claro */
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 768px) {
  #comidasSection {
    height: 100%;
    margin: 0;
  }
  .grid-container {
    grid-template-columns: 1fr; /* Cambiar a una sola columna */
  }

  .grid-item {
    flex-direction: column; /* Cambiar la dirección de los elementos a columna */
    justify-content: center; /* Centrar verticalmente los elementos */
    align-items: center; /* Centrar horizontalmente los elementos */
  }

  .grid-item img {
    width: 200px; /* Ancho del 100% para que ocupe todo el espacio disponible */
    height: 200px; /* Altura automática */
    margin-right: 0; /* Eliminar el margen derecho */
    margin-bottom: 10px; /* Agregar un margen inferior */
    border-radius: 50%; /* Eliminar el borde redondeado */
  }

  .grid-item h2 {
    font-size: 1em; /* Tamaño de fuente */
  }

  .grid-item p {
    font-size: 0.8em; /* Tamaño de fuente más pequeño */
  }
  .titulo-comidas {
    margin-top: 100px !important;
  }
}

@media (max-width: 1200px) {
  #comidasSection {
    height: 100%;
    margin: 0;
  }
  .grid-container {
    grid-template-columns: 1fr; /* Cambiar a una sola columna */
  }

  .grid-item {
    flex-direction: column; /* Cambiar la dirección de los elementos a columna */
    justify-content: center; /* Centrar verticalmente los elementos */
    align-items: center; /* Centrar horizontalmente los elementos */
  }

  .grid-item img {
    width: 200px; /* Ancho del 100% para que ocupe todo el espacio disponible */
    height: 200px; /* Altura automática */
    margin-right: 0; /* Eliminar el margen derecho */
    margin-bottom: 10px; /* Agregar un margen inferior */
    border-radius: 50%; /* Eliminar el borde redondeado */
  }

  .grid-item h2 {
    font-size: 1em; /* Tamaño de fuente */
  }

  .grid-item p {
    font-size: 0.8em; /* Tamaño de fuente más pequeño */
  }
  .titulo-comidas {
    margin-top: 100px !important;
  }
}
