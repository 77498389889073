#contactoSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.849);
  color: white;
}

.contenido-contacto {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contenido-contacto-left {
  width: 50vw;
  margin-left: 100px;
  margin-top: 50px;
}

.contenido-right {
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenido-contacto-right h2 {
  margin-top: 0;
  font-size: 24pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.contenido-center {
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Agregado */
}
.contenido-center h2 {
  font-size: 24pt;
}
.contenido-contacto-right p {
  margin-bottom: 10px;
  font-size: 15pt;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui.form .field > label {
  color: white;
}

@media (max-width: 768px) {
  #contactoSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.849);
    color: white;
  }
  .contenido-contacto {
    flex-direction: column; /* Cambiar la dirección de los elementos a columna */
    margin-top: 100px;
  }

  .contenido-center h2 {
    font-size: 24pt;
  }
  .contenido-contacto-right h2 {
    font-size: 24pt;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contenido-contacto-left,
  .contenido-right,
  .contenido-center {
    width: 100%; /* Hacer que los elementos ocupen todo el ancho */
    margin: 0; /* Eliminar los márgenes laterales */
  }

  .contenido-contacto-right p {
    font-size: 12pt; /* Reducir el tamaño de fuente */
  }
  .footer {
    font-size: 8pt;
  }
}

@media (max-width: 1200px) {
  #contactoSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.849);
    color: white;
  }
  .contenido-contacto {
    flex-direction: column; /* Cambiar la dirección de los elementos a columna */
    margin-top: 100px;
  }

  .contenido-center h2 {
    font-size: 24pt;
  }
  .contenido-contacto-right h2 {
    font-size: 24pt;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contenido-contacto-left,
  .contenido-right,
  .contenido-center {
    width: 100%; /* Hacer que los elementos ocupen todo el ancho */
    margin: 0; /* Eliminar los márgenes laterales */
  }

  .contenido-contacto-right p {
    font-size: 12pt; /* Reducir el tamaño de fuente */
  }
  .footer {
    font-size: 8pt;
  }
}
