.llegar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 100px;
}

.mapa {
  width: 65%;
  height: 65%;
  border-radius: 5%;
}
.titulo-llegar {
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
}

@media (max-width: 768px) {
  .llegar {
    margin-top: 1500px;
    margin: 0;
  }
  .mapa {
    width: 100%;
    height: 65%;
    border-radius: 5%;
  }
}
@media (max-width: 1200px) {
  .llegar {
    margin-top: 1500px;
    margin: 0;
  }
  .mapa {
    width: 100%;
    height: 65%;
    border-radius: 5%;
  }
}
