#nosotrosSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: center;
  padding: 20px;
  margin: 0 100px;
}

.Texto {
  flex: 1 1 400px;
  padding: 10px;
  margin: 0 20px;
  position: relative;
  display: flex; /* Agregado */
  flex-direction: column; /* Agregado */
  align-items: flex-start; /* Agregado */
}

.titulo-nosotros {
  font-size: 25pt;
}

.Texto::before {
  content: "";
  background-image: url("../../assets/logo.jpg");
  background-size: cover;
  position: absolute;
  width: 70%;
  height: 80%;
  background-position: center;
  opacity: 0.1;
}

#nosotrosSection p:first-of-type {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

#nosotrosSection h2 {
  font-weight: bold;
  font-size: 32pt;
  margin-bottom: 0.5rem;
}

#nosotrosSection p:last-of-type {
  text-align: left;
  font-size: 1.5rem;
}

.image-nosotros {
  width: 80%;
  height: 50%;
  border-radius: 10%;
}

@media (max-width: 768px) {
  #nosotrosSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 0;
    height: 100%;
    margin: 0;
  }

  #nosotrosSection p:first-of-type {
    font-weight: bold;
    margin-bottom: 0.1rem;
  }
  #nosotrosSection h2 {
    font-weight: bold;
    font-size: 32pt;
    margin-top: 0.1rem;
  }

  .Texto {
    padding: 20px;
    margin: 0;
    margin-top: 100px;
  }

  .image-nosotros {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1200px) {
  #nosotrosSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 0;
    height: 100%;
    margin: 0;
  }

  #nosotrosSection p:first-of-type {
    font-weight: bold;
    margin-bottom: 0.1rem;
  }
  #nosotrosSection h2 {
    font-weight: bold;
    font-size: 32pt;
    margin-top: 0.1rem;
  }

  .Texto {
    padding: 20px;
    margin: 0;
    margin-top: 100px;
  }

  .image-nosotros {
    width: 100%;
    height: auto;
  }
}
