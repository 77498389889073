@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import "@sweetalert2/theme-bulma/bulma.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

section::before {
  content: "";
  height: 1px;
  width: 100%;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 0.75) 50%,
    hsla(0, 0%, 0%, 0)
  );
  position: absolute;
  top: 0;
  left: 0;
}

.fixed-buttons-container {
  position: fixed;
  right: 0px; /* Puedes ajustar la distancia desde el borde derecho */
  top: 20%; /* Puedes ajustar la posición vertical */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fixed-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
  padding: 0; /* Agrega esta línea para eliminar el espacio de relleno */
}

.fixed-button:hover {
  opacity: 1;
}

.fixed-button img {
  width: 30px; /* Puedes ajustar el tamaño del icono */
  height: 30px;
  margin-right: 5px; /* Puedes ajustar el espacio entre el icono y el texto */
}
@media (max-width: 768px) {
  .fixed-buttons-container {
    display: none;
  }
}

@media (max-width: 1200px) {
  .fixed-buttons-container {
    display: none;
  }
}
