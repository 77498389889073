#inicioSection {
  padding: 20px;
  margin: 0 100px;
}
.active,
.dot:hover {
  background-color: black;
}
.active-thumbnail {
  border: black solid 3px;
}

.carousel-container {
  width: 100%;
  margin-top: 80px;
}
.carousel {
  width: 100%;
  height: auto;
}

.carousel-image {
  width: auto;
  height: 60vh;
  object-fit: cover;
}
.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  overflow: scroll;
}

@media (max-width: 1200px) {
  #inicioSection {
    padding: 20px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .thumbnails {
    display: none;
  }
}

@media (max-width: 768px) {
  #inicioSection {
    padding: 20px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .thumbnails {
    display: none;
  }
}
