.headers {
  background: white;
  color: black;
  padding: 1rem;
  position: fixed;
  width: 100%;
  box-shadow: 0 10px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.containers {
  max-width: 1200px;
  margin: 0 auto;
}

.navContainers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navContainers nav {
  display: flex;
  gap: 2.5rem;
}

.navContainers button {
  position: relative;
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  cursor: pointer;
  color: black;
  background: none;
  border: none;
}

.navContainers button::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: black;
  left: 0;
  bottom: -5px;
  transform: scale(0, 1);
  transition: transform 0.25s ease;
  transform-origin: 0 100%;
}

.navContainers button:hover::before {
  transform: scale(1, 1);
}

ul {
  list-style: none;
}

.logo {
  width: 100px;
}

.burgerMenu {
  display: none; /* Por defecto, ocultar el botón de menú "burger" */
  /* Estilos para el botón "burger" */
}

@media (max-width: 768px) {
  .headers {
    background: white;
    color: black;
    padding: 1rem;
    position: fixed;
    width: 100%;
    box-shadow: 0 10px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  .burgerMenu {
    display: block;
    position: relative;
    align-self: center;
    margin-left: auto;
    font-size: 2rem;
    height: 40px;
  }
  .burgerLine {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin-bottom: 5px;
  }

  .navContainers nav {
    display: none; /* Ocultar el menú principal en la resolución de pantalla menor o igual a 768px */
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f9f9f9;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navContainers nav.active {
    display: flex; /* Mostrar el menú desplegable cuando se active */
  }

  .navContainers button {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: black;
    cursor: pointer;
  }

  .navContainers button.burgerMenu {
    width: 20%;
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: black;
    cursor: pointer;
  }

  .navContainers button::before {
    display: none; /* Ocultar la línea debajo de los botones en el menú desplegable */
  }
}
