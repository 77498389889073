#hospedajeSection {
  text-align: center;
  margin: 0 100px;
  font-family: "Open Sans", sans-serif;
  padding: 20px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: black;
}

.titulo-hospedaje {
  text-align: center;
  font-size: 40px;
}

.carousel-row {
  display: flex;
  justify-content: space-between;
  gap: 150px;
  margin-top: 40px; /* Espacio entre los carruseles */
}

.carousel-image-hospedaje {
  max-width: 500px; /* Tamaño deseado para las imágenes */
  height: 300px; /* Ajusta la altura automáticamente */
  border-radius: 5%;
}

@media (max-width: 768px) {
  #hospedajeSection {
    height: 100%;
    margin: 0;
  }

  .titulo-hospedaje {
    text-align: center;
    font-size: 40px;
    margin-top: 100px !important;
  }

  .carousel-row {
    display: flex;
    flex-direction: column; /* Cambiar a una disposición de columna en pantallas más pequeñas */
    align-items: center; /* Centrar horizontalmente los elementos */
    gap: 20px; /* Reducir el espacio entre los carruseles */
    margin-top: 40px;
  }

  .carousel-image-hospedaje {
    max-width: 100%; /* Ajustar el ancho máximo a la pantalla */
    height: auto; /* Ajustar la altura automáticamente */
    border-radius: 10%;
  }
}

@media (max-width: 1200px) {
  #hospedajeSection {
    height: 100%;
    margin: 0;
  }

  .titulo-hospedaje {
    text-align: center;
    font-size: 40px;
    margin-top: 100px !important;
  }

  .carousel-row {
    display: flex;
    flex-direction: column; /* Cambiar a una disposición de columna en pantallas más pequeñas */
    align-items: center; /* Centrar horizontalmente los elementos */
    gap: 20px; /* Reducir el espacio entre los carruseles */
    margin-top: 40px;
  }

  .carousel-image-hospedaje {
    max-width: 100%; /* Ajustar el ancho máximo a la pantalla */
    height: auto; /* Ajustar la altura automáticamente */
    border-radius: 10%;
  }
}
