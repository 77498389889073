.lugares {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  height: 100vh;
  margin: 0 100px;
}

.list-posts-web {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90vw;
  height: 80vh;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.titulo-lugares {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  margin: 0;
  font-size: 32px;
}

.item {
  flex-basis: 48%;
  margin-bottom: 20px;
  height: 50vh; /* Ajusta la altura deseada */
  position: relative;
}

.image-lugar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5%;
}
.img-content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
  text-align: center;
  transition: all 0.3s ease-in-out 0.1s;
}
.img-content h3 {
  color: #fff;
  font-size: 2.2rem;
}

.img-content p {
  color: #fff;
  font-size: 1rem;
}

.item::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.871);
  opacity: 0;
  z-index: 1;
  border-radius: 5%;

  transform: scaleY(0);
  transform-origin: 100% 100%;
  transition: all 0.3s ease-in-out;
}

.item:hover::after {
  opacity: 1;
  transform: scaleY(1);
}

.item:hover .img-content {
  opacity: 1;
  top: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
}

.pagination .item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 5px;

  cursor: pointer;
}

.pagination .active.item {
  background-color: #ccc;
}

.pagination .disabled.item {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .lugares {
    margin: 0;
  }
  .list {
    justify-content: flex-start;
  }

  .item {
    flex-basis: 100%;
    height: auto;
  }
  .titulo-lugares {
    margin-top: 100px !important;
  }

  .image-lugar {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5%;
  }

  .img-content {
    top: 70%;
    left: 50%;
    width: 100%;
    font-size: 0.8rem; /* Ajusta el tamaño de la letra según sea necesario */
  }

  .img-content h3 {
    margin-right: 0; /* Elimina el margen derecho para que el título esté en línea con el párrafo */
    font-size: 1.2rem; /* Ajusta el tamaño del encabezado según sea necesario */
  }

  .img-content p {
    margin-left: 0;
    font-size: 0.8rem; /* Elimina el margen izquierdo para que el párrafo esté en línea con el título */
  }
}

@media (max-width: 1200px) {
  .lugares {
    margin: 0;
  }
  .list {
    justify-content: flex-start;
  }

  .item {
    flex-basis: 100%;
    height: auto;
  }
  .titulo-lugares {
    margin-top: 100px !important;
  }

  .image-lugar {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5%;
  }

  .img-content {
    top: 70%;
    left: 50%;
    width: 100%;
    font-size: 0.8rem; /* Ajusta el tamaño de la letra según sea necesario */
  }

  .img-content h3 {
    margin-right: 0; /* Elimina el margen derecho para que el título esté en línea con el párrafo */
    font-size: 1.2rem; /* Ajusta el tamaño del encabezado según sea necesario */
  }

  .img-content p {
    margin-left: 0;
    font-size: 0.8rem; /* Elimina el margen izquierdo para que el párrafo esté en línea con el título */
  }
}
